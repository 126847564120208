import React from "react";
import { graphql } from "gatsby";
import loadable from "@loadable/component";
import { dedicatedFAQList } from "../components/DedicatedTeams/DedicatedTeams";

const Layout = loadable(() => import("@common/Layout/Layout"));
const DedicatedTeams = loadable(() =>
    import("@components/DedicatedTeams/DedicatedTeams")
);
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const DedicatedTeamsPage = ({ path, data }) => (
    <>
        <SEO
            title="Hire Dedicated Development Team in Ukraine for Your Software Project"
            titleTemplate="Impressit"
            SEOData={data.allStrapiSeoDescriptions?.nodes}
            description="Hire dedicated development team in Ukraine to cover your software development needs. Wide variety of technologies; simple, fast and hassle-free onboarding process."
            path={path}
            faqSchema={dedicatedFAQList}
        />
        <Layout path={path} isNoPadding>
            <DedicatedTeams caseStudies={data.allStrapiCaseStudies} />
        </Layout>
    </>
);

export default DedicatedTeamsPage;

export const startProductDevelopmentQuery = graphql`
    query dedicatedTeamsAllCaseStudiesQuery {
        allStrapiCaseStudies {
            nodes {
                id
                logo {
                    url
                }
                wideImage {
                    url
                }
                metaUrl
                title
            }
        }
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
